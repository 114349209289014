.about {
  padding: 22px 20px;
  margin-bottom: 10px;
  background-color: #fff;
  border-left: 8px solid #33c0a3;
  box-shadow: 0 2px 2px 0 rgba(50,50,50,0.4);
  font-family: 'Roboto', sans-serif;
}
.about .title {
  font-size: 24px;
  color: #323232;
}
.about-paragraph {
  color: #676767;
  font-weight: 300;
  letter-spacing: 0.1px;
  font-size: 14px;
  line-height: 22px;
}
.about-paragraph.small {
  font-size: 12px;
}
.about-paragraph.padded {
  padding-bottom: 24px;
}
.about-repo {
  display: block;
  padding-bottom: 3px;
  color: #343333;
  font-size: 14px;
}
.about-repo-list {
  margin-left: 37px;
  list-style: disc;
  color: #33c0a3;
}
.about-repo-list + .about__paragraph {
  padding: 8px 0 5px;
}
.about-repo-item {
  padding-bottom: 14px;
}
.about-github {
  color: #33c0a3;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.1px;
}
.about-team-list {
  display: flex;
  flex-flow: row wrap;
}
.about-team-item {
  width: 50%;
  display: inline-flex;
  padding-bottom: 29px;
}
.about-team-item:last-child {
  padding-bottom: 0;
}
.about-team-item img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: #979797;
}
.about-team-item img + div {
  padding-left: 20px;
  padding-top: 15px;
}
.about-team-member {
  display: block;
  padding-bottom: 6px;
  color: #33c0a3;
  font-size: 12px;
}
@media (max-width: 640px) {
  .about-team-list {
    flex-flow: column nowrap;
  }
  .about-team-item {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    align-items: center;
    padding-bottom: 15px;
    text-align: center;
  }
  .about-team-item img + div {
    padding-left: 0;
  }
}
@media (min-width: 1024px) {
  .about-team-list {
    flex-flow: row nowrap;
  }
  .about-team-item {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding-bottom: 0;
    text-align: center;
  }
  .about-team-item img + div {
    padding-left: 0;
  }
}
