@import './common/variables.styl'

basic-input()
  width: 100%
  padding: 7px 5px
  border: none
  outline: none
  color: text-light
  font-family: font-roboto
  font-size: 13px
  font-weight: 300
  letter-spacing: -.3px
  &:focus
    &::placeholder
      color: rgba(text-light, .8)
  &::placeholder
    color: rgba(text-light, .6)
