@import './common/variables.styl'
@import './common/icons.styl'

.header
  position: fixed
  right: 0
  left: 0
  background-color: header-main-bg
  display: flex
  flex-flow: row nowrap
  justify-content: flex-start
  align-items: center
  height: offset
  z-index: 5
  box-shadow: 0 2px 2px 0 border-dark

  &-inner
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: center
    width: "calc(100% - %s)" % offset

  &-logo
    width: offset
    height: offset
    background-color: header-logo-bg
    & a
      display: flex
      height: 100%
      justify-content: center
      align-items: center
    & img
      width: 78%

  &-slogan
    color:text-dark
    font-family: font-raleway
    font-size: 23px
    font-weight: 600
    margin-left: 19px
    letter-spacing: .6px
    &-x
      color: text-green

  &-sign-in
    display: none
    pointer-events: none
    margin-right: 20px
    & a
      display: flex
      align-items: center
      text-decoration: none
    & span
      margin-right: 15px
      font-family: font-quicksand
      color: text-green
    & i
      font-size: 23px
      margin-top: 6px
      color: text-green
