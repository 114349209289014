html, body, div, span, h1, h2, h3, h4, h5, h6,
p, pre, a, code, img, i, ol, ul, li,
fieldset, form, label, article, aside, footer, header,
menu, nav, section
  margin: 0
  padding: 0
  border: 0
  font-size: 100%
  font: inherit
  vertical-align: baseline

article, aside,
footer, header, nav, section
  display: block

body
  line-height: 1

ol, ul
  list-style: none

body *
  box-sizing: border-box
