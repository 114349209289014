.recent-snippet {
  display: flex;
  flex-flow: column nowrap;
  font-family: 'Roboto', sans-serif;
}
.recent-snippet-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 10px;
  padding: 20px 16px 20px 20px;
  border-left: 8px solid #33c0a3;
  box-shadow: 0 2px 2px 0 rgba(50,50,50,0.4);
  background-color: #fff;
}
.recent-snippet-meta {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  max-width: calc(100% - 240px);
}
.recent-snippet-meta > div {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
.recent-snippet-meta-title {
  color: #676767;
  font-size: 19px;
  text-decoration: none;
}
.recent-snippet-meta-title:hover {
  color: #178770;
}
.recent-snippet-meta-author {
  padding-top: 13px;
  color: #979797;
  font-size: 14px;
}
.recent-snippet-meta-tags {
  margin: 10px 0;
}
.recent-snippet-meta-tag {
  margin-right: 5px;
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 300;
  line-height: 22px;
  color: #343333;
  border-radius: 9.5px;
  background-color: #eee;
  letter-spacing: 0.4px;
}
.recent-snippet-meta-tag:last-shild {
  margin-right: 0;
}
.recent-snippet-meta-info {
  opacity: 0.5;
  font-size: 13px;
  font-weight: 300;
  color: #444;
}
.recent-snippet-actions {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
.recent-snippet-lang {
  padding-bottom: 17px;
  text-align: right;
  font-size: 14px;
  color: #33c0a3;
}
.recent-snippet-button {
  margin-right: 0;
  padding: 10px 14px;
  color: #fff;
  border: none;
  background-color: #67c5b2;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}
.recent-snippet-button:hover {
  background-color: #33c0a3;
}
.recent-snippet-button.light {
  margin-right: 8px;
  padding: 9px 14px;
  color: #676767;
  border: 1px solid #e4e4e4;
  background-color: #eee;
}
.recent-snippet-button.light:hover {
  background-color: #e1e1e1;
}
.pagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding-top: 10px;
}
.pagination-item {
  padding: 9px 14px;
  font-size: 14px;
  font-weight: 300;
  color: #676767;
  background-color: #c6c6c6;
  cursor: pointer;
}
.pagination-item:hover {
  color: #fff;
  background-color: #33c0a3;
}
.pagination-item.next {
  margin-right: 4px;
}
.pagination-item.disabled {
  pointer-events: none;
  background-color: #e1e1e1;
}
@media (max-width: 768px) {
  .recent-snippet-item {
    flex-flow: column nowrap;
    padding: 15px;
  }
  .recent-snippet-meta {
    max-width: 100%;
  }
  .recent-snippet-meta-title {
    font-size: 16px;
  }
  .recent-snippet-meta-tags {
    margin: 5px 0;
  }
  .recent-snippet-actions div {
    text-align: right;
  }
  .recent-snippet-lang {
    padding-bottom: 8px;
  }
}
