@import './common/variables.styl'
@import './common/overwrite.styl'
@import './common/mixins.styl'

lang-bar-width = 230px
lang-bar-width-tablet = 170px

.new-snippet
  display: flex
  min-height: 83vh
  background-color: snippet-content-light
  box-shadow: 0 2px 2px 0 border-dark
  &-code,
  &-lang
    &-header
      display: flex
      flex-flow: row nowrap
      min-height: offset
      align-items: flex-start
      padding: 20px 20px 19px
      box-sizing: border-box

  &-code
    flex: 1
    position: relative
    padding: 0 0 63px 0
    &-wrapper
      display: flex
      flex-flow: column nowrap
      width: "calc(100% - %s)" % lang-bar-width
    &-header
      border-left: 34px solid snippet-header-normal
      background-color: snippet-header-light
      .input
        basic-input()
        width: 47%
        border-radius: 3px
        background-color: snippet-header-normal
      & > input
        margin-right: 5%
    &-bottom-bar
      position: absolute
      bottom: 0
      left: 0
      right: 0
      text-align: right
      padding: 5px
      border-top: 1px solid border-light
      & > input
        position: relative
        min-width: 200px
        padding: 11px
        margin: 5px 16px 7px 0
        border: none
        background-color: snippet-post-button
        color: text-light
        font-size: 15px
        text-align: center
        z-index: 2
        -webkit-appearance: none // we need this styles to overwrite safary's styles for input with type='submit'
        -webkit-border-radius: 0
        &:hover,
        &:focus
          background-color: snippet-post-button-active
          cursor: pointer

  &-lang
    &-wrapper
      display: flex
      flex-flow: column nowrap
      width: lang-bar-width
      background-color: language-bar-bg-normal
    &-header
      background-color: snippet-header-normal
      .input
        basic-input()
        border-radius: 3px
        background-color: snippet-header-lang-input-bg
    &-list
      height: 100%
      &-wrapper
        flex: 1
    &-empty
      padding: 15px
      font-size: 14px
      font-weight: 300
      color: text-grey
    &-item
      margin-top: -1px
      padding: 16px 15px
      font-size: 14px
      font-family: font-roboto
      font-weight: 300
      letter-spacing: .2px
      border-left: 5px solid language-bar-border
      border-bottom: 1px solid language-bar-border
      cursor: pointer
      &:hover,
      &.active
        padding-bottom: 17px
        border-left: 5px solid snippet-border
        border-bottom: none
        background-color: language-bar-bg-active

@media (max-width: tablet-breakpoint)
  .new-snippet
    &-code,
    &-lang
      &-header
        flex-flow: column nowrap
        min-height: 105px

    &-code
      &-wrapper
        width: "calc(100% - %s)" % lang-bar-width-tablet
      &-header
        .input
          width: 100%
          margin-bottom: 8px
        .ReactTags__tags
          width: 100%
        & > input
          margin-right: 0

    &-lang
      &-wrapper
        width: lang-bar-width-tablet
