@font-face {
  font-family: "IconFont";
  src: url("../assets/fonts/icon-font.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "IconFont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-about:before {
  content: "\61";
}
.icon-close:before {
  content: "\62";
}
.icon-new:before {
  content: "\63";
}
.icon-recent:before {
  content: "\64";
}
.icon-tools:before {
  content: "\65";
}
.icon-upload:before {
  content: "\66";
}
.icon-user:before {
  content: "\67";
}
.header {
  position: fixed;
  right: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  z-index: 5;
  box-shadow: 0 2px 2px 0 rgba(50,50,50,0.4);
}
.header-inner {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 70px);
}
.header-logo {
  width: 70px;
  height: 70px;
  background-color: #33c0a3;
}
.header-logo a {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.header-logo img {
  width: 78%;
}
.header-slogan {
  color: #343333;
  font-family: 'Raleway', sans-serif;
  font-size: 23px;
  font-weight: 600;
  margin-left: 19px;
  letter-spacing: 0.6px;
}
.header-slogan-x {
  color: #2aa88e;
}
.header-sign-in {
  display: none;
  pointer-events: none;
  margin-right: 20px;
}
.header-sign-in a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.header-sign-in span {
  margin-right: 15px;
  font-family: 'Quicksand', sans-serif;
  color: #2aa88e;
}
.header-sign-in i {
  font-size: 23px;
  margin-top: 6px;
  color: #2aa88e;
}
