.snippet {
  display: flex;
  flex-flow: column nowrap;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(50,50,50,0.4);
}
.snippet-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  min-height: 110px;
  padding: 15px 20px;
  color: #fff;
  background: #33c0a3;
  border-left: 40px solid #11a88d;
  font-family: 'Roboto', sans-serif;
}
.snippet-meta-tags {
  margin: 10px 0;
}
.snippet-meta-tag {
  margin-right: 5px;
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 300;
  color: #fff;
  border-radius: 9.5px;
  background-color: rgba(255,255,255,0.2);
  letter-spacing: 0.4px;
}
.snippet-meta-tag:last-shild {
  margin-right: 0;
}
.snippet-data {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  max-width: calc(100% - 312px);
}
.snippet-data-title {
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.2px;
}
.snippet-data-author {
  display: block;
  margin-top: 7px;
  font-size: 14px;
}
.snippet-data-actions {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-end;
}
.snippet-data-lang {
  font-size: 14px;
}
.snippet-data-meta {
  color: #caf3ec;
  font-size: 12px;
  font-weight: 300;
}
.snippet-button {
  margin-right: 10px;
  padding: 11px 21px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  border-radius: 0;
  border: 1px solid #fff;
  background-color: #33c0a3;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
}
.snippet-button:last-child {
  margin-right: 0;
}
.snippet-button.show,
.snippet-button:hover {
  background-color: #fff;
  color: #33c0a3;
}
.snippet-button.embed {
  margin-left: 10px;
  padding: 8px 18px;
  font-size: 12px;
  text-transform: none;
  border: none;
}
.snippet-button.embed:hover {
  color: #fff;
  background-color: #11a88d;
}
.snippet-embed {
  display: block;
  position: relative;
  max-height: 180px;
  transition: max-height 0.5s ease-in;
  background-color: #eee;
  border-left: 40px solid #e1e1e1;
  overflow: hidden;
}
.snippet-embed.false {
  max-height: 0;
  transition: max-height 0.5s ease-out;
}
.snippet-embed-content {
  padding: 15px 20px;
}
.snippet-embed-text {
  padding-bottom: 10px;
  color: #343333;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 12px;
}
.snippet-embed input {
  width: 100%;
  padding: 7px 5px;
  border: none;
  outline: none;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.3px;
  padding: 4px 9px;
  width: auto;
  min-width: 450px;
  min-height: 30px;
  color: #343333;
  background-color: #fff;
  font-size: 13px;
}
.snippet-embed input:focus::placeholder {
  color: rgba(255,255,255,0.8);
}
.snippet-embed input::placeholder {
  color: rgba(255,255,255,0.6);
}
.snippet-embed-close {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.snippet-embed-close::before,
.snippet-embed-close::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 14px;
  left: 6px;
  background-color: #979797;
}
.snippet-embed-close::before {
  transform: rotate(45deg);
}
.snippet-embed-close::after {
  transform: rotate(-45deg);
}
@media (max-width: 768px) {
  .snippet-header {
    flex-flow: column nowrap;
  }
  .snippet-data {
    max-width: 100%;
  }
  .snippet-data-lang {
    padding-bottom: 8px;
  }
  .snippet-data-title {
    font-size: 17px;
    line-height: 17px;
  }
  .snippet-button {
    padding: 9px 16px;
    font-size: 12px;
  }
  .snippet-button.embed {
    margin-top: 8px;
    margin-left: 0;
  }
  .snippet-embed-close {
    display: none;
  }
  .snippet-embed input {
    min-width: 100%;
  }
}
