.sidebar {
  position: fixed;
  width: 70px;
  height: 100%;
  margin-top: 70px;
  background-color: #343333;
  z-index: 6;
}
.sidebar-list {
  width: 100%;
}
.sidebar-item {
  position: relative;
  height: 70px;
}
.sidebar-item-border {
  border-bottom: 1px solid rgba(238,238,238,0.1);
}
.sidebar-item a {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-decoration: none;
}
.sidebar-item a.active,
.sidebar-item a:hover {
  background-color: #272727;
}
.sidebar-item a.active i,
.sidebar-item a:hover i {
  color: #64beac;
}
.sidebar-item a.active::after,
.sidebar-item a:hover::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: -13px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  border-left: solid transparent 9px;
  border-right: solid transparent 9px;
  border-top: solid #272727 9px;
}
.sidebar-item i {
  font-size: 27px;
  margin-top: 6px;
  color: #6b6b6b;
}
