@import './common/variables.styl'

.about
  padding: 22px 20px
  margin-bottom: 10px
  background-color: snippet-content-light
  border-left: 8px solid about-border
  box-shadow: 0 2px 2px 0 border-dark
  font-family: font-roboto
  & .title
    font-size: 24px
    color: about-title
  &-paragraph
    color: about-paragraph
    font-weight: 300
    letter-spacing: .1px
    font-size: 14px
    line-height: 22px
    &.small
      font-size: 12px
    &.padded
      padding-bottom: 24px

  &-repo
    display: block
    padding-bottom: 3px
    color: text-dark
    font-size: 14px
    &-list
      margin-left: 37px
      list-style: disc
      color: about-point
      & + .about__paragraph
        padding: 8px 0 5px
    &-item
      padding-bottom: 14px

  &-github
    color: snippet-border
    font-size: 14px
    font-weight: 300
    letter-spacing: .1px

  &-team
    &-list
      display: flex
      flex-flow: row wrap
    &-item
      width: 50%
      display: inline-flex
      padding-bottom: 29px
      &:last-child
        padding-bottom: 0
      & img
        border-radius: 50%
        width: 100px
        height: 100px
        background-color: about-image-backup
        & + div
          padding-left: 20px
          padding-top: 15px
    &-member
      display: block
      padding-bottom: 6px
      color: about-point
      font-size: 12px

@media (max-width: mid-tablet-breakpoint)
  .about
    &-team
      &-list
        flex-flow: column nowrap
      &-item
        display: flex
        flex-flow: column nowrap
        width: 100%
        align-items: center
        padding-bottom: 15px
        text-align: center
        & img
          & + div
            padding-left: 0

@media (min-width: mid-desktop-breakpoint)
  .about
    &-team
      &-list
        flex-flow: row nowrap
      &-item
        display: flex
        flex-flow: column nowrap
        align-items: center
        padding-bottom: 0
        text-align: center
        & img + div
          padding-left: 0
