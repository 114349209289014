.snippet .ace_cursor {
  display: none !important;
}
.snippet .ace_gutter-layer {
  width: 40px !important;
}
.snippet .ace_gutter-cell {
  padding-right: 5px !important;
  padding-left: 0;
}
.snippet .ace_scroller {
  margin-left: 15px !important;
}
.new-snippet .ace_editor {
  min-height: 100% !important;
}
.snippet-code .ace_text-input-ios {
  position: relative !important;
}
.ReactTags__tags {
  width: 100%;
  padding: 7px 5px;
  border: none;
  outline: none;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.3px;
  width: 47%;
  padding: 3px 5px 0;
  min-height: 29px;
  height: 29px;
  overflow-y: auto;
  border-radius: 3px;
  background-color: #2aa88e;
}
.ReactTags__tags:focus::placeholder {
  color: rgba(255,255,255,0.8);
}
.ReactTags__tags::placeholder {
  color: rgba(255,255,255,0.6);
}
.ReactTags__tag {
  display: inline-block;
  margin: 0 4px 4px 0;
  padding: 0 8px;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: #fff;
  border-radius: 9.5px;
  background-color: rgba(51,192,163,0.7);
  letter-spacing: 0.4px;
  white-space: nowrap;
}
.ReactTags__tag:last-child {
  margin-right: 0;
}
.ReactTags__tag:hover {
  border-color: #fff;
}
.ReactTags__tag .ReactTags__remove {
  margin-left: 3px;
  cursor: pointer;
}
.ReactTags__tagInput {
  display: inline;
  color: #fff;
}
.ReactTags__tagInputField {
  width: 100%;
  padding: 7px 5px;
  border: none;
  outline: none;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.3px;
  width: auto;
  padding: 2px 0 4px 0;
  border-radius: 3px;
  background-color: #2aa88e;
}
.ReactTags__tagInputField:focus::placeholder {
  color: rgba(255,255,255,0.8);
}
.ReactTags__tagInputField::placeholder {
  color: rgba(255,255,255,0.6);
}
.ReactTags__selected > .ReactTags__tagInput {
  width: 100%;
}
.ReactTags__selected > .ReactTags__tagInput .ReactTags__tagInputField {
  width: 100%;
}
.ReactTags__tag + .ReactTags__tagInput {
  width: auto;
}
.ReactTags__tag + .ReactTags__tagInput .ReactTags__tagInputField {
  width: auto;
}
.new-snippet {
  display: flex;
  min-height: 83vh;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(50,50,50,0.4);
}
.new-snippet-code-header,
.new-snippet-lang-header {
  display: flex;
  flex-flow: row nowrap;
  min-height: 70px;
  align-items: flex-start;
  padding: 20px 20px 19px;
  box-sizing: border-box;
}
.new-snippet-code {
  flex: 1;
  position: relative;
  padding: 0 0 63px 0;
}
.new-snippet-code-wrapper {
  display: flex;
  flex-flow: column nowrap;
  width: calc(100% - 230px);
}
.new-snippet-code-header {
  border-left: 34px solid #2aa88e;
  background-color: #33c0a3;
}
.new-snippet-code-header .input {
  width: 100%;
  padding: 7px 5px;
  border: none;
  outline: none;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.3px;
  width: 47%;
  border-radius: 3px;
  background-color: #2aa88e;
}
.new-snippet-code-header .input:focus::placeholder {
  color: rgba(255,255,255,0.8);
}
.new-snippet-code-header .input::placeholder {
  color: rgba(255,255,255,0.6);
}
.new-snippet-code-header > input {
  margin-right: 5%;
}
.new-snippet-code-bottom-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: right;
  padding: 5px;
  border-top: 1px solid #eee;
}
.new-snippet-code-bottom-bar > input {
  position: relative;
  min-width: 200px;
  padding: 11px;
  margin: 5px 16px 7px 0;
  border: none;
  background-color: #33c0a3;
  color: #fff;
  font-size: 15px;
  text-align: center;
  z-index: 2;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
.new-snippet-code-bottom-bar > input:hover,
.new-snippet-code-bottom-bar > input:focus {
  background-color: #11a88d;
  cursor: pointer;
}
.new-snippet-lang-wrapper {
  display: flex;
  flex-flow: column nowrap;
  width: 230px;
  background-color: #eee;
}
.new-snippet-lang-header {
  background-color: #2aa88e;
}
.new-snippet-lang-header .input {
  width: 100%;
  padding: 7px 5px;
  border: none;
  outline: none;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.3px;
  border-radius: 3px;
  background-color: #178770;
}
.new-snippet-lang-header .input:focus::placeholder {
  color: rgba(255,255,255,0.8);
}
.new-snippet-lang-header .input::placeholder {
  color: rgba(255,255,255,0.6);
}
.new-snippet-lang-list {
  height: 100%;
}
.new-snippet-lang-list-wrapper {
  flex: 1;
}
.new-snippet-lang-empty {
  padding: 15px;
  font-size: 14px;
  font-weight: 300;
  color: #979797;
}
.new-snippet-lang-item {
  margin-top: -1px;
  padding: 16px 15px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  letter-spacing: 0.2px;
  border-left: 5px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  cursor: pointer;
}
.new-snippet-lang-item:hover,
.new-snippet-lang-item.active {
  padding-bottom: 17px;
  border-left: 5px solid #33c0a3;
  border-bottom: none;
  background-color: #fdfdfd;
}
@media (max-width: 768px) {
  .new-snippet-code-header,
  .new-snippet-lang-header {
    flex-flow: column nowrap;
    min-height: 105px;
  }
  .new-snippet-code-wrapper {
    width: calc(100% - 170px);
  }
  .new-snippet-code-header .input {
    width: 100%;
    margin-bottom: 8px;
  }
  .new-snippet-code-header .ReactTags__tags {
    width: 100%;
  }
  .new-snippet-code-header > input {
    margin-right: 0;
  }
  .new-snippet-lang-wrapper {
    width: 170px;
  }
}
