@import './common/variables.styl'
@import './common/mixins.styl'

button-group-width = 312px

.snippet
  display: flex
  flex-flow: column nowrap
  background-color: snippet-content-light
  box-shadow: 0 2px 2px 0 border-dark
  &-header
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    min-height: 110px
    padding: 15px 20px
    color: text-light
    background: snippet-header-green-normal
    border-left: 40px solid snippet-header-border
    font-family: font-roboto

  &-meta
    &-tags
      margin: 10px 0
    &-tag
      margin-right: 5px
      padding: 3px 8px
      font-size: 12px
      font-weight: 300
      color: text-light
      border-radius: 9.5px
      background-color: rgba(text-light, .2)
      letter-spacing: .4px
      &:last-shild
        margin-right: 0

  &-data
    display: flex
    flex-flow: column nowrap
    justify-content: space-between
    max-width: "calc(100% - %s)" % button-group-width
    &-title
      font-size: 20px
      line-height: 20px
      letter-spacing: .2px
    &-author
      display: block
      margin-top: 7px
      font-size: 14px
    &-actions
      display: flex
      flex-flow: column nowrap
      justify-content: space-between
      align-items: flex-end
    &-lang
      font-size: 14px
    &-meta
      color: snippet-header-data
      font-size: 12px
      font-weight: 300

  &-button
    margin-right: 10px
    padding: 11px 21px
    color: text-light
    font-size: 14px
    font-weight: 400
    border-radius: 0
    border: 1px solid text-light
    background-color: button-active
    text-transform: uppercase
    cursor: pointer
    text-decoration: none
    &:last-child
      margin-right: 0
    &.show
    &:hover
      background-color: text-light
      color: button-active
    &.embed
      margin-left: 10px
      padding: 8px 18px
      font-size: 12px
      text-transform: none
      border: none
      &:hover
        color: text-light
        background-color: button-embed-hover

  &-embed
    display: block
    position: relative
    max-height: 180px
    transition: max-height .5s ease-in
    background-color: snippet-header-embed
    border-left: 40px solid snippet-header-embed-dark
    overflow: hidden
    &.false
      max-height: 0
      transition: max-height .5s ease-out
    &-content
      padding: 15px 20px
    &-text
      padding-bottom: 10px
      color: text-dark
      font-family: font-roboto
      font-weight: 300
      font-size: 12px
    & input
      basic-input()
      padding: 4px 9px
      width: auto
      min-width: 450px
      min-height: 30px
      color: text-dark
      background-color: snippet-content-light
      font-size: 13px
    &-close
      position: absolute
      width: 12px
      height: 12px
      top: 15px
      right: 15px
      cursor: pointer
      &::before
      &::after
        content: ''
        position: absolute
        width: 1px
        height: 14px
        left: 6px
        background-color: snippet-embed-close
      &::before
        transform: rotate(45deg)
      &::after
        transform: rotate(-45deg)

@media (max-width: tablet-breakpoint)
  .snippet
    &-header
      flex-flow: column nowrap

    &-data
      max-width: 100%
      &-lang
        padding-bottom: 8px
      &-title
        font-size: 17px
        line-height: 17px

    &-button
      padding: 9px 16px
      font-size: 12px
      &.embed
        margin-top: 8px
        margin-left: 0

    &-embed
      &-close
        display: none
      & input
        min-width: 100%
