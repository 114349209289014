html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
a,
code,
img,
i,
ol,
ul,
li,
fieldset,
form,
label,
article,
aside,
footer,
header,
menu,
nav,
section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
footer,
header,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
body * {
  box-sizing: border-box;
}
html,
body,
body > div {
  height: 100%;
}
.content {
  display: flex;
  min-height: 100%;
}
.main {
  padding: 27px 20px;
  width: calc(100% - 70px);
  min-width: 480px;
  min-height: 100%;
  margin-top: 70px;
  margin-left: 70px;
  overflow-y: auto;
  background-color: #e8e8e8;
  font-family: 'Quicksand', sans-serif;
}
