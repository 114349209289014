@font-face
  font-family: "IconFont"
  src: url("../assets/fonts/icon-font.woff") format("woff")
  font-weight: normal
  font-style: normal

[class^="icon-"]:before,
[class*=" icon-"]:before
  font-family: "IconFont"
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.icon-about:before
  content: "\61"

.icon-close:before
  content: "\62"

.icon-new:before
  content: "\63"

.icon-recent:before
  content: "\64"

.icon-tools:before
  content: "\65"

.icon-upload:before
  content: "\66"

.icon-user:before
  content: "\67"
