.notification {
  display: none;
  position: absolute;
  top: 11px;
  right: 240px;
  padding: 11px 25px;
  background-color: #d2be70;
  color: #fff;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  z-index: 1;
}
.notification.has-error {
  display: block;
  animation: slideIn 0.5s ease-in;
}
@-moz-keyframes slideIn {
  0% {
    margin-right: -216px;
  }
  100% {
    margin-right: 0;
  }
}
@-webkit-keyframes slideIn {
  0% {
    margin-right: -216px;
  }
  100% {
    margin-right: 0;
  }
}
@-o-keyframes slideIn {
  0% {
    margin-right: -216px;
  }
  100% {
    margin-right: 0;
  }
}
@keyframes slideIn {
  0% {
    margin-right: -216px;
  }
  100% {
    margin-right: 0;
  }
}
